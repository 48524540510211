import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const userData = localStorage.getItem("userData");

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...require("./pages/" + getPathName(userData)).default,
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Admin/Modules/Authentication/Login.vue"),
      meta: {
        layout: "full",
        NorequiresAuth: true,
      },
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import("@/views/Admin/Modules/Authentication/Register/Register.vue"),
      meta: {
        layout: "full",
        NorequiresAuth: true,
      },
    },
    {
      path: "/recoverPassword",
      name: "recoverPassword",
      component: () =>
        import("@/views/Admin/Modules/Authentication/recoverPassword/Recover.vue"),
      meta: {
        layout: "full",
        NorequiresAuth: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        NorequiresAuth: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
      NorequiresAuth: true,
    },
    {
      path: "/e-commerce/shop/public",
      name: "apps-e-commerce-shop-public",
      component: () =>
        import(
          "@/views/apps/e-commerce/e-commerce-shop/ECommerceShopSecond.vue"
        ),
      meta: {
        contentRenderer: "sidebar-left-detached",
        contentClass: "ecommerce-application",
        pageTitle: "Tienda",
        NorequiresAuth: true,
        breadcrumb: [
          {
            text: "Tienda",
          },
          {
            text: "Productos",
            active: true,
          },
        ],
      },
    },
  ],
});

function getPathName(userData) {
  if (userData == null) {
    return "other";
  } else {
    const user = JSON.parse(userData);
    if (user.role == "admin") {
      return "admin";
    } else if (user.role == "cashier") {
      return "cashier";
    } else if (
      user.role == "student" ||
      user.role == "teacher" ||
      user.role == "representative"
    ) {
      return "other";
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (!to.matched.some((record) => record.meta.NorequiresAuth)) {
    store.commit('metaPage/setPageTitle', to.meta.pageTitle)

    if (
      localStorage.getItem("userData") &&
      localStorage.getItem("accessToken")
    ) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    return next();
  }
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
