import axios from "@axios";
import router from "@/router";
import { password } from "@/@core/utils/validations/validations";

export default {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/login", user)
        .then((res) => {
          commit("SET_INFO_USER", res.data);
          commit("SET_PERMISSIONS", res.data.userData.permissions);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/auth/logout")
        .then((res) => {
          // remove token
          commit("REMOVE_DATA_USER");
          resolve(res);
        })
        .catch((err) => {
          commit("REMOVE_DATA_USER");
          reject(err);
        });
    });
  },
  passwordRecovery({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/forgot-password', payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  validateCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/validate-code', payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/reset-password', payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/auth/user")
        .then((res) => {
          commit("SET_INFO_USER_VALID", res.data);
          commit("SET_PERMISSIONS", res.data.permissions);
          resolve(res);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            commit("REMOVE_DATA_USER");
            router.push({ name: "login" });
          } else if (err.response.status == 500) {
            commit("REMOVE_DATA_USER");
            router.push({ name: "login" });
          }
          reject(err);
        });
    });
  },

  sendWhatsAppMessage({ commit }, idUser) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/user/debt-by-whatsapp/${idUser}`).then((res) => {
        resolve(res);
      });
    }).catch((err) => {
      reject(err);
    });
  },

  fetchUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/users", { params })
        .then((res) => {
          commit("SET_USERS", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRepresentative({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/users", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  storeUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/users`, user)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  schoolProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/school/profile`, user)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/users/${payload.id}`, payload.data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sameUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/sameUsers/${payload.id}`, payload.data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchRoles({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/roles")
        .then((res) => {
          commit("SET_ROLES", res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  showUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/${id}`)
        .then((res) => {
          commit("SET_USER", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  searchUserInWallet({ commit }, walletId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/search-user-in-wallet/${walletId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  rechargeMode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/recharge/" + payload.walletId, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  withdrawalMode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/users/withdrawal/${payload.walletId}`, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  fetchSchools({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/schools")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/users/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  mergeChilds({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/merge-childs/${payload.userId}`, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  mergeChildsSave({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/merge-childs-save/${payload.userId}`, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restrictProducts({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/restrict-products`, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addObservation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/add-observation/${payload.userId}`, payload.data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  handleCheckboxChange({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/user/self-search-product", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  searchProduct({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/user/search-product", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadAvatar({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/upload-avatar/${payload.userId}`, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  updatePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/update-user-password/${payload.userId}`, {
          password: payload.password,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  searchUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/search-user", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteChild({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/delete-child/${payload.childId}`, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addNoteChild({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/add-note-child/${payload.childId}`, payload.data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  searchAllUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/search-all/users", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendMsg({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/user/send-msg`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
