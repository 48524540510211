import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import store from './store'
import router from './router'
import App from './App.vue'

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registrado con éxito:', registration);

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('Nueva versión disponible. Recargando...');
                window.location.reload();
              } else {
                console.log('Contenido cacheado por primera vez.');
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Error al registrar el Service Worker:', error);
      });
  });
}

const { pathname } = window.location
if (pathname.includes('/redirect/')) {
  const hasArr = pathname.split('/')
  const hasid = hasArr[hasArr.length - 1]
  store.dispatch('link/redirectToLink', hasid).then(res => {
    window.location = res.data.link.link
  }).catch(err => {
    console.log(err)
  })
}

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput, {
  mode: 'international',
  validCharactersOnly: true,
  onlyCountries: [
    'ar',
    'bo',
    'br',
    'cl',
    'co',
    'ec',
    'gy',
    'pe',
    'py',
    'uy',
    've',
    'eu',
    'gb',
    'us',
    'de',
    'es'
  ],
  inputOptions:{
    placeholder: '+X 999 999 999'
  }
});

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import assets styles
require('@/assets/scss/style.scss')

import vuescroll from 'vuescroll'
Vue.use(vuescroll)

import moment from 'moment'
Vue.prototype.moment = moment

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,
  {
    symbol : '$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)

Vue.config.productionTip = false

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

const token = localStorage.getItem('accessToken')

window.Echo = new Echo({
  cluster: 'us2',
  broadcaster: 'pusher',
  key: '9109b40119726bda6ca9',
  wsHost: '127.0.0.1:8000',
  authEndpoint: 'http://127.0.0.1:8000/broadcasting/auth',
  // wsHost: 'andinoapp.com',
  // authEndpoint: 'https://andinoapp.com/broadcasting/auth',
  encrypted: true,
  forceTLS: false,
  wsPort: 6001,
  wssPort: 6001,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
